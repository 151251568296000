import {axiosData} from "@/store/api/axios";

const state = () => ({
  tipocarnets: null || JSON.parse(localStorage.getItem('tipocarnets')),
  tipocarnet: null || JSON.parse(localStorage.getItem('tipocarnet'))
});

const getters = {
  getTipocarnets(state) {
    return state.tipocarnets;
  },
  getTipocarnet(state) {
    return state.tipocarnet;
  },

};

const actions = {
  setTipocarnet({ commit }, payload) {
    commit('SET_TIPOCARNET', payload);
    payload
        ? localStorage.setItem('tipocarnet', JSON.stringify(payload))
        : localStorage.removeItem('tipocarnet');
  },
  setTipocarnets({ commit }, payload) {
    commit('SET_TIPOCARNETS', payload);
    payload
        ? localStorage.setItem('tipocarnets', JSON.stringify(payload))
        : localStorage.removeItem('tipocarnets');
  },
  async getTipocarnetByMembresia(_,negocioId){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'tipocarnets/negocio/membresia/'+negocioId,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async getTipocarnetsByTemporadaId(_,id){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'tipocarnets/temporada/'+id,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async getTipocarnetsByTag(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'tipocarnets/negocio/tag/'+tag,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async getTipocarnetsById(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'tipocarnets/negocio/'+tag,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async deleteTipocarnet(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'tipocarnets/borrar',
          payload,
          'delete',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async saveTipocarnets(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'tipocarnets/editar',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
};

const mutations = {
  SET_TIPOCARNETS(state, payload) {
    state.tipocarnets = payload;
    localStorage.setItem('tipocarnets', JSON.stringify(payload));
  },
  SET_TIPOCARNET(state, payload) {
    state.tipocarnet = payload;
    localStorage.setItem('tipocarnet', JSON.stringify(payload));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
