import {axiosData} from "@/store/api/axios";

const state = () => ({
  tickets: localStorage.getItem('tickets') ? JSON.parse(localStorage.getItem('tickets')) : null,
  ticket: localStorage.getItem('ticket') ? JSON.parse(localStorage.getItem('ticket')) : null
});

const getters = {
  getTickets(state) {
    return state.tickets;
  },
  getTicket(state) {
    return state.ticket;
  },
};

const actions = {
  setTicket({ commit }, payload) {
    commit('SET_TICKET', payload);
    payload
        ? localStorage.setItem('ticket', JSON.stringify(payload))
        : localStorage.removeItem('ticket');
  },
  setTickets({ commit }, payload) {
    commit('SET_TICKETS', payload);
    payload
        ? localStorage.setItem('tickets', JSON.stringify(payload))
        : localStorage.removeItem('tickets');
  },
  async reenviarEmail(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Reenviando Email');

    try {

    await axiosData(
          true,
          'tickets/reenviar/email',
          payload,
          'post',
          false,
          false
      );


        this.dispatch('ui/handleLoaderBtn', false);

    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async grabarPuntos(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Cambiando Puntos');

    try {

      const data = await axiosData(
          true,
          'tickets/puntos',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },

  async emailMasivoTicket(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Grabando Tickets');

    try {

      const data = await axiosData(
          true,
          'tickets/email/cortesias',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async nuevoTicket(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Grabando Tickets');

    try {

      const data = await axiosData(
          true,
          'tickets/nuevo',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },

  async grabarCambioEstado(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Cambiando Estado');

    try {

      const data = await axiosData(
          true,
          'tickets/cambiaestado',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async grabarFormularioInka2(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'tickets/grabar/formulario/inka/2',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async grabarFormularioInka1(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'tickets/grabar/formulario/inka',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async saveQrCode(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'tickets/qrcode',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        this.dispatch('listas/setListas', data);

        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async grabarFormulario(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Grabando Formulario');
    try {
      const data = await axiosData(
          true,
          'tickets/grabar/formulario',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async getTicketsReporteEntrada(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      let queryParams = '';
      if(payload.puertaFiltroRequest){
        // Obtener los filtros de tiempo desde el estado o de alguna fuente en el frontend
        const fromTime = payload.puertaFiltroRequest.fromTime;
        const toTime = payload.puertaFiltroRequest.toTime;

        // Construir la URL con los parámetros de consulta, si están presentes

        if (fromTime && toTime) {
          queryParams = `?fromTime=${fromTime}&toTime=${toTime}`;
        }
      }
      const data = await axiosData(
          true,
          `tickets/evento/reporte/entradas/${payload.id}${queryParams}`,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return null;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async getTicketsReporteZona(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      let queryParams = '';
      if(payload.puertaFiltroRequest){
        // Obtener los filtros de tiempo desde el estado o de alguna fuente en el frontend
        const fromTime = payload.puertaFiltroRequest.fromTime;
        const toTime = payload.puertaFiltroRequest.toTime;

        // Construir la URL con los parámetros de consulta, si están presentes

        if (fromTime && toTime) {
          queryParams = `?fromTime=${fromTime}&toTime=${toTime}`;
        }
      }
      const data = await axiosData(
          true,
          `tickets/evento/reporte/zona/${payload.id}${queryParams}`,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return null;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async getTicketsReportePromotor(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      let queryParams = '';
      if(payload.puertaFiltroRequest){
        // Obtener los filtros de tiempo desde el estado o de alguna fuente en el frontend
        const fromTime = payload.puertaFiltroRequest.fromTime;
        const toTime = payload.puertaFiltroRequest.toTime;

        // Construir la URL con los parámetros de consulta, si están presentes

        if (fromTime && toTime) {
          queryParams = `?fromTime=${fromTime}&toTime=${toTime}`;
        }
      }
      const data = await axiosData(
          true,
          `tickets/evento/reporte/promotor/${payload.id}${queryParams}`,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return null;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async getTicketsReporteGraph(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      let queryParams = '';
      if(payload.puertaFiltroRequest){
        // Obtener los filtros de tiempo desde el estado o de alguna fuente en el frontend
        const fromTime = payload.puertaFiltroRequest.fromTime;
        const toTime = payload.puertaFiltroRequest.toTime;

        // Construir la URL con los parámetros de consulta, si están presentes

        if (fromTime && toTime) {
          queryParams = `?fromTime=${fromTime}&toTime=${toTime}`;
        }
      }
      const data = await axiosData(
          true,
          `tickets/evento/reporte/graph/${payload.id}${queryParams}`,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return null;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async getTicketsSparkline(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Cargando Reporte');
    try {
      let queryParams = '';
      if(payload.puertaFiltroRequest){
        // Obtener los filtros de tiempo desde el estado o de alguna fuente en el frontend
        const fromTime = payload.puertaFiltroRequest.fromTime;
        const toTime = payload.puertaFiltroRequest.toTime;

        // Construir la URL con los parámetros de consulta, si están presentes

        if (fromTime && toTime) {
          queryParams = `?fromTime=${fromTime}&toTime=${toTime}`;
        }
      }
      // Hacer la solicitud GET con los parámetros en la URL
      const data = await axiosData(
          true,
          `tickets/evento/sparkline/${payload.id}${queryParams}`,
          'get',
          false,
          false
      );

      if (data) {
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      } else {
        this.dispatch('ui/handleLoaderBtn', false);
        return null;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
    },
  async getTicketsEventos(_,id){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Cargando Tickets');
    try {
      const data = await axiosData(
          true,
          'actos/eventos/activos/'+id,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);

        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async getTicketsLista(_,id){
    this.dispatch('ui/handleLoaderBtn', true);

    this.dispatch('ui/setTextLoading', 'Cargando Tickets');
    try {
      const data = await axiosData(
          true,
          'tickets/evento/todos/'+id,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);

        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async ingresaTicketsCortesiaQR(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'tickets/evento/qr',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);

        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async getTicketsDashboard(_,id){
    this.dispatch('ui/handleLoaderBtn', true);

    this.dispatch('ui/setTextLoading', 'Cargando Tickets');
    try {
      const data = await axiosData(
          true,
          'tickets/dashboard/eventos/'+id,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);

        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);

      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async getTicketsByEvent(_,id){
    this.dispatch('ui/handleLoaderBtn', true);

    this.dispatch('ui/setTextLoading', 'Cargando Tickets');
    try {
      const data = await axiosData(
          true,
          'tickets/evento/'+id,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);

        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);

      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async getTicketsByPerson(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);

    this.dispatch('ui/setTextLoading', 'Cargando Tickets');
    try {
      let pagina = payload.page - 1;
      const data = await axiosData(
          true,
          'tickets/'+payload.id+'?page='+pagina+'&size=6',
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        this.dispatch('tickets/setTickets', data);

        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }}
};

const mutations = {
  SET_TICKETS(state, payload) {
    state.tickets = payload;
    localStorage.setItem('tickets', JSON.stringify(payload));
  },
  SET_TICKET(state, payload) {
    state.ticket = payload;
    localStorage.setItem('ticket', JSON.stringify(payload));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
