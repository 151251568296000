import {axiosData} from "@/store/api/axios";

const state = () => ({
  tipolistas: null || JSON.parse(localStorage.getItem('tipolistas')),
  tipolista: null || JSON.parse(localStorage.getItem('tipolista'))
});

const getters = {
  getTipolistas(state) {
    return state.tipolistas;
  },
  getTipolista(state) {
    return state.tipolista;
  },

};

const actions = {
  setTipolista({ commit }, payload) {
    commit('SET_TIPOLISTA', payload);
    payload
        ? localStorage.setItem('tipolista', JSON.stringify(payload))
        : localStorage.removeItem('tipolista');
  },
  setTipolistas({ commit }, payload) {
    commit('SET_TIPOLISTAS', payload);
    payload
        ? localStorage.setItem('tipolistas', JSON.stringify(payload))
        : localStorage.removeItem('tipolistas');
  },
  async getTipolistasById(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'tipolistas/negocio/'+tag,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async deleteTipolista(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'tipolistas/borrar',
          payload,
          'delete',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async saveTipolistas(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'tipolistas/editar',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
};

const mutations = {
  SET_TIPOLISTAS(state, payload) {
    state.tipolistas = payload;
    localStorage.setItem('tipolistas', JSON.stringify(payload));
  },
  SET_TIPOLISTA(state, payload) {
    state.tipolista = payload;
    localStorage.setItem('tipolista', JSON.stringify(payload));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
